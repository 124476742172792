<template>
    <div class="uf hi100 chat-user">
        <!--            right--聊天内容-->
        <div class="uf-f1 uf uf-column uf-ac" v-loading="loading">
            <!--                头部标题-->
            <div style="height: 50px;width: 100%;border-bottom: 1px solid #eeeded" class="uf uf-pc uf-ac p-2 pr">
                <div class="f18 fwb">{{doctorInfo.name}}</div>
                <div class="title-close" @click="handleClose">
                    <i class="el-icon-arrow-left"></i>
                </div>
            </div>
            <!--                聊天内容-->
            <el-scrollbar class="uf-f1 wi100 sys-zd" ref="msgBox" id="chatScroll">
                <div class="p-2" >
                    <div class="uf uf-ac uf-pc mb-2" v-if="topLoading">
                        <i class="el-icon-loading"></i>
                    </div>
                    <div v-for="(item,index) in messageList" :key="index">
                        <!--                    聊天时间-->
                        <template v-if="item.time ">
                            <div class="uf uf-ac uf-pc mb-2" v-if="getTimeDiff(item,index)">
                                <div class="chat-time">{{moment(item.time).format('YYYY年MM月DD日 HH:mm:ss')}}</div>
                            </div>
                        </template>
                        <!--                    专家聊天内容-->
                        <div class="uf uf-ac mb-2" v-if="item.role === 'doctor'">
                            <el-avatar shape="square" style="width: 45px;height: 45px;"
                                       :src="doctorInfo.headPath ? $cons.SERVER_PATH + doctorInfo.headPath : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                            <div class="sys-text" v-if="item.type === 'TEXT'">{{item.content}}</div>
                            <div v-else class="" style="margin-left: 12px;">
                                <el-image
                                        style="max-width: 200px"
                                        fit="scale-down"
                                        :src="$cons.SERVER_PATH + item.content"
                                        :preview-src-list="srcList">
                                </el-image>
                            </div>
                        </div>
                        <!--                   用户聊天内容-->
                        <div>
                            <div class="uf uf-ac uf-pe mb-2" v-if="item.role === 'user'">
                                <div class="expert-text" v-if="item.type === 'TEXT'">{{item.content}}</div>
                                <div v-else class="" style="margin-right: 12px;">
                                    <el-image
                                            style="max-width: 200px"
                                            fit="scale-down"
                                            :src="item.ongoing ? item.content : $cons.SERVER_PATH + item.content"
                                            :preview-src-list="srcList">
                                    </el-image>
                                </div>
                                <el-avatar shape="square" style="width: 45px;height: 45px;"
                                           :src="$cons.SERVER_PATH + userHead"></el-avatar>
                            </div>
                        </div>
                        <!--                    结束语-->
                        <!--              <div class="uf uf-ac uf-pc mb-2">-->
                        <!--                <div class="auxiliary-text">问诊结束，如有疑问可再次咨询！</div>-->
                        <!--              </div>-->
                    </div>

                </div>
            </el-scrollbar>
            <!--                聊天输入-->
            <div style="width:100%;border-top: 1px solid #eeeded" class="uf uf-column p-2">
                <!--                输入框--title（图片）-->
<!--                <div class="uf uf-ac uf-pj">-->
<!--                    <div>-->
<!--                        <el-upload-->
<!--                                class="upload-demo"-->
<!--                                :action="$cons.API_SERVER_PATH + $api.QUALITY_DOCTOR.UPLOAD_IMAGE"-->
<!--                                :data="uploadData"-->
<!--                                :headers="uploadHeaders"-->
<!--                                accept=".jpg,.jpeg,.JPG,.png,.PNG"-->
<!--                                :on-preview="handlePreview"-->
<!--                                :on-exceed="handleExceed"-->
<!--                                :on-success="handleSuccess"-->
<!--                                :show-file-list="false">-->
<!--                            <i class="el-icon-picture f20 cp"></i>-->
<!--                        </el-upload>-->
<!--                    </div>-->
<!--                </div>-->
                <!--                    输入框-->
                <div class="uf-f1 uf uf-ac">
                    <div class="uf-f1">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="contentText" resize="none"
                                  :autosize="{ minRows: 2, maxRows: 3 }" ref="sendMsg" @keyup.enter.native="sendText('TEXT')"></el-input>
                    </div>
                    <!--                    发送按钮-->
                    <div class="uf uf-pe ml-2">
                        <el-button type="primary" size="small" @click="sendText('TEXT')">发送</el-button>
                        <div class="uf uf-ac ml-1">
                            <i class="el-icon-picture f26 cp"></i>
                            <input type="file" class="upload" @change="addImg" ref="inputer" style="opacity: 0;width: 24px;margin-left: -24px;"
                                   accept="image/png,image/jpeg,image/jpg" capture="camera"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import Vue from 'vue'
    let Base64 = require('js-base64').Base64
    export default {
        name: 'chat-user',
        data () {
            return {
                moment,
                // webSocket-链接
                ws: null,
                dialogVisible: false,
                userName: '',
                search: '',
                //  消息类型（进行中，已完成)--默认显示进行中
                activeName: 'conduct',
                //   当前选中的消息
                isSelect: '',
                //   输入消息内容
                contentText: '',
                //   图片列表
                srcList: [],
                doctorInfo: {},
                uploadData: {
                    fromId: '',
                    toId: ''
                },
                messageList: [],
                uploadHeaders: {
                    token: Vue.cookie.get('marketToken')
                },
                //    历史消息记录分页参数
                page: 1,
                limit: 30,
                totalCount: 0,
                totalPage: 0,
                //  滑动触顶加载标记
                topLoading: false,
                loading: false,
                scrollHeight: 0,
                imageUrl: '',
                // 是否显示消息成员列表
                isList: false,
                //  消息成员列表
                //  消息列表分页参数
                pageIndex: 1,
                pageSize: 10,
                expertList: [],
                expertTotalPage: 0,
                fil: {}
            }
        },
        created () {
            this.doctorInfo = this.$route.query.doctorInfo || {}
        },
        mounted () {
            this.initWebSocket()
            this.getChatRecord()
            let that = this
            document.getElementById('chatScroll').addEventListener('scroll', that.drugsScroll, true)
        },
        computed: {
            userId: {
                get () { return this.$store.state.user.id },
                set (val) { this.$store.commit('user/updateId', val) }
            },
            userHead: {
                get () { return this.$store.state.user.userHead },
                set (val) { this.$store.commit('user/updateUserHead', val) }
            }
        },
        methods: {
            // 滚动事件
            drugsScroll () {
                let scrollTop = this.$refs.msgBox.$refs.wrap.scrollTop
                let clientHeight = this.$refs.msgBox.$refs.wrap.clientHeight
                let scrollHeight = this.$refs.msgBox.$refs.wrap.scrollHeight
                // console.log(scrollTop, clientHeight, scrollHeight)
                if (scrollTop + clientHeight === scrollHeight) {
                    // console.log('触底加载')
                }
                if (scrollTop === 0) {
                    if (this.page < this.totalPage) {
                        this.scrollHeight = this.$deepClone(scrollHeight)
                        this.topLoading = true
                        this.page = this.page + 1
                        this.getChatRecord(true)
                    }

                }
            },
            //  获取当前消息成员列表
            getMessageList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
                this.dataListLoading = true
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.MESSAGE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize,
                    })
                }).then(({data}) => {
                    this.dataListLoading = false
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.expertList = page.list || []
                        this.expertTotalPage = page.totalPage || 0
                        if (this.expertList && this.expertList.length > 0) {
                            this.doctorInfo = this.expertList[0].sysUserEntity || {}
                            this.isSelect = this.expertList[0].toId || ''
                            this.initWebSocket()
                            this.getChatRecord()
                        } else {
                            this.$message.warning('当前无消息记录')
                            this.handleClose()
                        }
                    }
                })
            },
            // 消息切换
            expertSwitch (item) {
                this.ws.close()
                this.isSelect = item.toId || ''
                this.doctorInfo = item.sysUserEntity || {}
                this.messageList = []
                this.page = 1
                this.limit = 30
                this.totalPage = 1
                this.initWebSocket()
                this.getChatRecord()
            },
            // 查询聊天记录
            getChatRecord (isLoading = false) {
                this.loading = true
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.CHAT_RECORD),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.page,
                        limit: this.limit,
                        fromId: this.userId,
                        toId: this.doctorInfo.userId
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        let list = page.list || []
                        this.totalPage = page.totalPage || 0
                        if (list && list.length > 0) {
                            if (isLoading) {
                                list.forEach(item => {
                                    if (item.fromId === this.userId) {
                                        this.messageList.unshift({
                                            role: 'user',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    } else {
                                        this.messageList.unshift({
                                            role: 'doctor',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    }
                                    if (item.msgType === 'IMAGE') {
                                        this.srcList.unshift(this.$cons.SERVER_PATH  + item.msgText)
                                    }
                                })
                                this.topLoading = false
                                this.$nextTick(() => {
                                    this.$refs['msgBox'].wrap.scrollTop = this.$refs['msgBox'].wrap.scrollHeight - this.scrollHeight
                                })
                            } else {
                                let messageList = list.reverse()
                                messageList.forEach(item => {
                                    if (item.fromId === this.userId) {
                                        this.messageList.push({
                                            role: 'user',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    } else {
                                        this.messageList.push({
                                            role: 'doctor',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    }
                                    if (item.msgType === 'IMAGE') {
                                        this.srcList.push(this.$cons.SERVER_PATH + item.msgText)
                                    }
                                })
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        this.scrollBottm()
                                    }, 500)
                                })
                            }
                        }
                    }
                    this.loading = false
                })
            },
            // 进入页面创建websocket连接
            initWebSocket () {
                let _this = this
                // 判断页面有没有存在websocket连接
                if (window.WebSocket) {
                    let socketUrl = ''
                    // let fromId = '0125412'
                    if (process.env.NODE_ENV === 'development') {
                        socketUrl = 'ws://localhost:9081/smes-server'
                    } else {
                        // socketUrl = 'ws://www.chealth.cn/smes-server'
                        socketUrl = 'ws://manage.smartquality.cn/smes-server'
                    }
                    this.uploadData.fromId = this.userId || ''
                    this.uploadData.toId = this.doctorInfo.userId || ''
                    var url = `${socketUrl}/wsim/${this.userId}/${this.doctorInfo.userId}`
                    let ws = new WebSocket(url)
                    _this.ws = ws
                    ws.onopen = function (e) {
                        ws.onmessage = function (e) {
                            // 接收服务器返回的数据
                            let data = Base64.decode(e.data)
                            let type = ''
                            let content = data.substring(20)
                            let time = moment(Number(data.substring(0,10) * 1000)).format('YYYY-MM-DD HH:mm:ss')
                            if (data.indexOf('TEXT') !== -1) {
                                type = data.substring(10,14)
                            } else {
                                type = data.substring(10,15)
                            }
                            _this.messageList.push({
                                role: 'doctor',
                                type: type,
                                content: content,
                                headPath: '',
                                time: time
                            })
                            _this.srcList.push(this.$cons.SERVER_PATH + content)
                            _this.scrollBottm()
                        }
                    }
                    // ws.onclose = function (e) {
                    //   console.log('服务器连接关闭: ' + url)
                    // }
                    ws.onerror = function () {
                        // console.log('服务器连接出错: ' + url)
                    }
                }
            },
            // 发送聊天信息
            sendText (type) {
                let _this = this
                _this.$refs['sendMsg'].focus()
                if (!_this.contentText && type === 'TEXT') {
                    this.$message.warning('请输入消息内容')
                    return
                }
                let data = type === 'TEXT' ? `TEXT@@@@@@${_this.contentText}` : `IMAGE@@@@@${_this.headPath}`
                // 调用WebSocket send()发送信息的方法
                // _this.ws.send(JSON.stringify(data))
                let contentText = this.$deepClone(_this.contentText)
                if (_this.ws.readyState === 1) {
                    _this.ws.send(Base64.encode(data))
                    _this.contentText = ''
                    setTimeout(() => {
                        this.messageList.push({
                            role: 'user',
                            type: type,
                            content: type === 'TEXT' ? contentText : this.imageUrl,
                            headPath: '',
                            ongoing: true,
                            time: moment().format('YYYY-MM-DD HH:mm:ss')
                        })
                        _this.scrollBottm()
                    }, 500)
                } else {
                    this.$message.error('当前链接已断开，请关闭弹框重新链接')
                }
            },
            // 滚动条到底部
            scrollBottm () {
                let el = this.$refs['msgBox']
                this.$nextTick(() => {
                    el.wrap.scrollTop = el.wrap.scrollHeight
                })
            },
            // 计算时间差
            getTimeDiff (item, index) {
                let list = this.$deepClone(this.messageList)
                if (index === 0) {
                    return true
                }
                if (index > 0) {
                    if (moment(item.time).diff(moment(list[index - 1].time), 'minute') > 3) {
                        return true
                    } else {
                        return false
                    }
                }
            },
            //   图片上传进行时
            handlePreview () {
            },
            //   图片上传超出限制
            handleExceed () {
            },
            //   图片上传成功
            handleSuccess (res, file, fileList) {
                if (res && res.code === 0) {
                    this.headPath = res.path || ''
                    this.imageUrl = URL.createObjectURL(file.raw)
                    this.srcList.push(this.imageUrl)
                    this.sendText('IMAGE')
                } else {
                    this.$message.error(res.msg)
                }
            },
            //   关闭
            handleClose () {
                this.$router.go(-1)
            },
            //   消息类型切换
            handleClick () {
            },
            //  h5--页面图片上传
            addImg (event) {
                let inputDOM = this.$refs.inputer
                // 通过DOM取文件数据
                this.fil = inputDOM.files
                let size = Math.floor(this.fil[0].size / 1024)
                if (size > 1 * 1024 * 1024) {
                    this.$message.error('请选择1M以内的图片！')
                    return false
                }
                let formData = new FormData()
                formData.append('file', this.fil[0])
                formData.append('fromId', this.uploadData.fromId)
                formData.append('toId', this.uploadData.toId)
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.UPLOAD_IMAGE),
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
                    }
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.headPath = data.path || ''
                        this.imageUrl = URL.createObjectURL(this.fil[0])
                        this.srcList.push(this.imageUrl)
                        this.sendText('IMAGE')
                        // if (window.createObjectURL !== undefined) { // basic
                        //   this.imageUrl = window.createObjectURL(this.fil[0])
                        // } else if (window.URL !== undefined) { // mozilla(firefox)
                        //   this.imageUrl = window.URL.createObjectURL(this.fil[0])
                        // } else if (window.webkitURL !== undefined) { // webkit or chrome
                        //   this.imageUrl = window.webkitURL.createObjectURL(this.fil[0])
                        // }
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .chat-user{
        height: 100vh;
        background-color: #fff;
    }
    .chat-user /deep/ .el-textarea__inner {
        border: none;
    }

    .chat-user /deep/ .el-image__preview {
        border-radius: 4px;
    }
    /*图片加载失败*/
    .chat-user /deep/ .el-image__error{
        height: 100px;
        width: 140px;
        border-radius: 4px;
    }
    .select-style {
        background-color: #fff;
    }

    .title-close {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        font-size: 24px;
    }
    /*    文本溢出隐藏*/
    .text-hiding {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    /*    用户聊天气泡*/
    .sys-text {
        position: relative;
        padding: 8px;
        font-size: 14px;
        background-color: #c0ccda;
        border: 1px solid #c0ccda;
        border-radius: 4px;
        max-width: 80%;
        margin-left: 12px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .sys-text::before {
        content: '';
        position: absolute;
        /*width: 10px;*/
        /*height: 10px;*/
        left: -10px;
        top: 50%;
        transform: translateY(-10px);
        /*background-color: #c0ccda;*/
        /*border-radius: 40px 0 0 0;*/
        border-top: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid #c0ccda;
        border-bottom: 5px solid transparent;
    }

    /*    专家聊天气泡*/
    .expert-text {
        position: relative;
        padding: 8px;
        font-size: 14px;
        background-color: #3db94d;
        border: 1px solid #3db94d;
        color: #fff;
        border-radius: 4px;
        /*max-width: 300px;*/
        max-width: 80%;
        margin-right: 12px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .expert-text:after {
        content: '';
        position: absolute;
        /*width: 10px;*/
        /*height: 10px;*/
        right: -10px;
        top: 50%;
        transform: translateY(-10px);
        /*background-color: #c0ccda;*/
        /*border-radius: 40px 0 0 0;*/
        border-top: 5px solid transparent;
        border-left: 5px solid #3db94d;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }

    /*    辅助聊天气泡*/
    .auxiliary-text {
        padding: 5px 8px;
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
    }

    /*    聊天时间*/
    .chat-time {
        padding: 2px 5px;
        /*color: #fff;*/
        background-color: #eeeded;
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
    }

    /*element滚动条*/
    .chat-box /deep/ .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
    .el-select-dropdown__wrap {
        max-height: 500px !important;
        min-height: 130px !important;
    }
</style>
